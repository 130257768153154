/** 
 * 汽车行业
 */
<template>
  <v-card class="AutomobileIndustry pb-15">
    <v-banner
        single-line
        height="400"
        class="banner"
      >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerAutomobileIndustry400.jpg"
          height="100%"
          :transition="false"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0 max-width-1200" style="margin-top: 130px;">
          <v-card color="transparent" flat>
            <v-card-title class="white--text pa-0 font-size-50 font-weight-bold font-size-banner-title">
              汽车行业解决方案
            </v-card-title>
            <v-card-title class="white--text part1 pa-0 mt-7 font-size-banner-tip" style="line-height:30px">
              3D模型+VR全景技术全面展示车辆的特性<br/>搭配热点技术，将潜在客户向线下引流
            </v-card-title>
            <!-- <v-card-actions class="pa-0 mt-16">
              <v-btn
                  rounded
                  width="160"
                  height="50"
                  color="#ffffff"
                  class="font-size-20"
              >
                <span style="color: #333333;">立即咨询</span>
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-container>
      </v-img>
    </v-banner>  
    <!-- 详细介绍 -->
     <v-container class="containerStyle max-width-1200">
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class="pr-10"  width="600px">
          <img src="https://h5.ophyer.cn/official_website/other/car01.png" />
        </div>
        <div class="text-wrap" >
          <v-card class="pa-0" flat>
            <v-card-title class="con_tit pa-0 mb-5 mt-16">高精度可交互的3D模型</v-card-title>
            <v-card-text class="con_text pa-0">采用智能引擎制作高精度的3D模型，通过云端技术渲染后推送到客户端，让购车群体随时随地便捷体验到超高清、强交互、高沉浸感的三维可视化汽车模型</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
        <div class="pl-10">
          <img src="https://h5.ophyer.cn/official_website/other/car02.png" />
        </div>
        <div class="text-wrap" >
          <v-card class="pa-0"  flat>
            <v-card-title class="con_tit pa-0 mb-5 mt-16" >全面细致的车辆内部全景</v-card-title>
            <v-card-text class="con_text pa-0">车辆内部720°VR全景，让用户身临其境的了解车辆的座椅中控等模块，细致的看车体验，让每一寸细节刻在心里，达到与线下看车的同样效果</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class="pr-10"  width="600px">
          <img src="https://h5.ophyer.cn/official_website/other/car03.png" />
        </div>
        <div class="text-wrap" >
          <v-card class="pa-0" flat>
            <v-card-title class="con_tit pa-0 mb-5 mt-16">灵活多样的全景热点</v-card-title>
            <v-card-text class="con_text pa-0">可以将试驾表格嵌入场景热点，用户点就填写后便可达到想线下4S店引流的效果，还可以在场景内添加视频热点，点击可以获取更多车辆细节</v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-container> 
  </v-card>
</template>

<script>
let _this;
  export default {
    name: 'AutomobileIndustry',

    components: {
    },
    data () {
      return {
        name: 'CateringTrade',
        bannerLoad: false,
        imgLoad: false,
        windowHight: window.innerHeight/2,
        sticky: false,
      }
    },
    mounted(){
      _this = this;
      this.bannerLoad = true;
    },
    methods: {
      handleLoad: ()=>{
        _this.imgLoad = true;
        console.log("ewf")
      }
    }
  }
</script>

<style lang="scss" scoped>
.containerStyle{
  margin-top: 80px;
  .part-one{
    margin-bottom: 60px;
    // width: 600px;
    // height: 300px;
    img{
      border-radius: 10px;
      width: 400px;
      height: 260px;
      object-fit: contain;
    }
    .text-wrap{
      width: 600px;
      .con_tit{
        font-size: 24px;
        color: #333333;
        line-height: 30px;
        margin-top:84px
      }
      .con_text{
        height: 40px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
      }
      .banner-btn {
        margin-top: 50px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.banner{
  .part1{
    width: 388px;
    line-height: 1.5;
  }
}
</style>
